import React from 'react';
import PropTypes from 'prop-types';
import { Filter } from '@components/atoms';
import {
  StyledCategoryFilterSection,
  StyledColumn,
  StyledColWithDivider,
  StyledImageContainer,
  StyledButton,
  StyledMiddleContent,
  StyledMiddleContainer,
  HeadlineContainer,
  CtaLink,
  FilterAccordionContainer,
} from './CategoryFilter.style';
import { Text, Image } from '@Omegapoint/components';
import CategoryLinksComponent from './components/CategoryLinks/CategoryLinks';
import useTranslation from '@hooks/Translation';
import useBreakpoint from '@hooks/useBreakpoint';
import { Container, Row } from '@Omegapoint/components/layout';
import { withTheme } from 'styled-components';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
} from '@components/Accordion';

const CategoryFilterComponent = ({
  categoryLinks,
  categoryTitle,
  categoryDescription,
  categoryImage,
  categoryFilters,
  activeFilters,
  setActiveFilters,
  ctaLink,
  theme,
}) => {
  const isXl = useBreakpoint('xl', 'up');
  const isCompact = useBreakpoint('sm', 'down');
  const { translate } = useTranslation();

  const handleFilterChange = (id) => {
    let filter = [...activeFilters];
    const contains = activeFilters.includes(id);
    if (contains) {
      filter = filter.filter((existingIds) => existingIds !== id);
    } else {
      filter.push(id);
    }
    setActiveFilters(filter);
  };

  const showImages =
    process.env.GATSBY_ENABLE_ASSORTMENT_CATEGORY_IMGS !== 'false';

  return (
    <StyledCategoryFilterSection>
      <Container>
        <Row colGutter={40}>
          <StyledColumn col={12} md={9} xl={5}>
            <Text color={theme.Color.white} uppercase variant="h3">
              {translate('Products')}
            </Text>
            <CategoryLinksComponent categoryLinks={categoryLinks} />
          </StyledColumn>
          {isXl && (
            <StyledColWithDivider xl={4}>
              <Text color={theme.Color.white} uppercase variant="h3" caps>
                <span
                  dangerouslySetInnerHTML={{
                    __html: categoryTitle,
                  }}
                />
              </Text>
              <StyledMiddleContainer>
                <StyledMiddleContent narrow={showImages}>
                  <Text variant="p" color={theme.Color.white}>
                    {categoryDescription}
                  </Text>
                </StyledMiddleContent>
                {showImages && (
                  <StyledImageContainer>
                    <Image
                      height={'135px'}
                      width={'135px'}
                      src={categoryImage}
                    />
                  </StyledImageContainer>
                )}
              </StyledMiddleContainer>
              {ctaLink && (
                <CtaLink to={ctaLink}>
                  <StyledButton variant="info" outline size="medium">
                    {translate('ReadMore')}
                  </StyledButton>
                </CtaLink>
              )}
            </StyledColWithDivider>
          )}
          <StyledColumn col={12} md={3}>
            {isCompact ? (
              <FilterAccordionContainer>
                <Accordion>
                  <AccordionHeader color={theme.Color.white}>
                    <HeadlineContainer>
                      <Text
                        color={theme.Color.white}
                        uppercase
                        variant="h3"
                        dense
                      >
                        {translate('Filter')}
                      </Text>
                    </HeadlineContainer>
                  </AccordionHeader>
                  <AccordionContent>
                    <Filter
                      filters={categoryFilters}
                      handleChange={handleFilterChange}
                      activeFilters={activeFilters}
                    />
                  </AccordionContent>
                </Accordion>
              </FilterAccordionContainer>
            ) : (
              <>
                <HeadlineContainer>
                  <Text color={theme.Color.white} uppercase variant="h3">
                    {translate('Filter')}
                  </Text>
                </HeadlineContainer>
                <Filter
                  filters={categoryFilters}
                  handleChange={handleFilterChange}
                  activeFilters={activeFilters}
                />
                <StyledButton
                  variant="info"
                  outline
                  size="medium"
                  onPress={() => setActiveFilters([])}
                >
                  {translate('Show_All')}
                </StyledButton>
              </>
            )}
          </StyledColumn>
        </Row>
      </Container>
    </StyledCategoryFilterSection>
  );
};

CategoryFilterComponent.propTypes = {
  className: PropTypes.string,
  categoryLinks: PropTypes.any,
  categoryTitle: PropTypes.string,
  categoryDescription: PropTypes.string,
  categoryImage: PropTypes.string,
  categoryFilters: PropTypes.array,
  activeFilters: PropTypes.array,
  setActiveFilters: PropTypes.func,
  ctaLink: PropTypes.string,
};

export default withTheme(CategoryFilterComponent);
